import React from "react";
import background from "../images/0063 - Military Road Dover Heights 3.jpg";

export default function FeaturePanelQuickImage() {
  return (
    <div className="featurePanelQuickImage">
      <img src={background} alt="test" />
    </div>
  );
}
