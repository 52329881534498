import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import FeaturePanel from "../components/FeaturePanel";
import MountingTypeThumbnailList from "../components/MountingTypeThumbnailList";
import FeaturePanelQuickImage from "../components/FeaturePanelQuickImage";

export default function HomePage({ data }) {
  // const mountings = data.mountings.nodes;
  // const productImages = data.productImages.nodes;
  // console.log(mountings);

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Homepage" key="ogtitle" />
      </Helmet>
      <main id="main">
        <div className="pt-small px-medium">
          <div className="">
            <FeaturePanelQuickImage />
          </div>
        </div>
        <div className="pt-small pb-small px-medium">
          <div className="">
            <FeaturePanel />
          </div>
          {/* <div className="mt-fixed-xxxl">
            <MountingTypeThumbnailList
              mountings={mountings}
              productImages={productImages}
            />
          </div> */}
          {/* max-width-1000 */}
          {/* <div className="mt-fixed-xxxl pb-large fs-xl fs-ml-xxxl">
            <p>Tools for precise lighting treatments.</p>
            <p>
              We believe that effective lighting plays an essential role in
              enriching our experiences within the built environment. To that
              end, Ratio products are developed with a focus on visual comfort.
              Technical proficiency and a wide range of optics allow for the
              creation of specific lighting treatments, while discreet forms
              ensure our products blend seamlessly into contemporary
              architecture.
            </p>
          </div> */}
        </div>
      </main>
    </>
  );
}

/*  */

// export const query = graphql`
//   query {
//     mountings: allAirtableMountings(
//       filter: { data: { webPublishedHomepage: { eq: true } } }
//     ) {
//       nodes {
//         data {
//           # Mounting_Surface
//           # Mounting_Type
//           Name
//           T2 {
//             recordId
//             data {
//               webHeroImage {
//                 data {
//                   Filename
//                 }
//               }
//               webDisplayNameFirst
//               webDisplayNameSecond
//               webDisplayNameSecondShort
//               webFullName
//               webPublishedResults
//               webT4Slug
//               webSlug
//               webPower
//               webFluxRange
//               webDistribution
//             }
//           }
//           webDisplayOrder
//         }
//       }
//     }
//     productImages: allImageSharp {
//       nodes {
//         gatsbyImageData(
//           formats: JPG
//           jpgOptions: { quality: 75 }
//           sizes: "(min-width: 2000px) calc(25vw - 60px), (min-width: 1400px) calc(33.28vw - 63px), (min-width: 1100px) calc(50vw - 72px), (min-width: 600px) calc(50vw - 36px), calc(100vw - 32px)"
//           placeholder: BLURRED
//           width: 1200
//         )
//         parent {
//           ... on File {
//             relativePath
//           }
//         }
//       }
//     }
//   }
// `;
