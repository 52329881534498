import React from "react";
import background from "../images/feature-interval26mm-feature-background.jpg";

export default function FeaturePanel() {
  return (
    <div className="featurePanelGeneric">
      <div className="feature-background">
        <img src={background} alt="test" />
      </div>
      <div className="content">
        <div className="content-top fs-medium fs-ml-medium fs-mxl-medium fs-mxxl-large fs-mxxxl-xl">
          <div className="pb-small">
            <h3 className="fw-medium">Interval 26mm</h3>
            <h3 className="">48V Magnetic Track</h3>
          </div>
        </div>
        <div className="content-bottom">
          <p className="max-width-mxxxl-400 fs-medium fs-ml-medium fs-mxl-medium fs-mxxl-large fs-mxxxl-xl">
            Full-featured adjustable spotlights on a magnetic track. Petite,
            discreet and all about flexbility.
          </p>
          <div className="content-cta pt-small">
            <a
              title="Configure"
              className="capsule-link button-capsule-link"
              href="/feature/interval/"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
